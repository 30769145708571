<template>
  <div class="wholesale-department">
    <div class="wholesale-department__container _container">
      <BreadCrumbs :items="[[$t('main'), '/'], [$t('nav.news')]]" />
    </div>
    <main-news />
  </div>
</template>

<script>
import MainNews from "../components/home/MainNews.vue";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";

export default {
  components: { BreadCrumbs, MainNews },
  data() {
    return {};
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style lang="scss">
.wholesale-department {
  padding: 50px 0 126px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 67px 0;
  }

  &__title {
    margin: 0 0 38px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 21px 0;
    }
  }

  &__text {
    max-width: 850px;
    &:not(:last-child) {
      color: #d81921;
      margin: 0 0 17px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 16px 0;
      }
    }
  }

  &__top {
    margin: 0 0 59px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 47px 0;
    }
  }

  &__content {
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: flex-start;
      gap: 0 75px;
    }
    @media (max-width: $pc + px) {
      gap: 0 50px;
    }
  }

  &__tabs {
    flex: 0 0 465px;
  }

  .tab-content {
    @media (max-width: $tablet + px) {
      padding: 47px 0 0 0;
    }
  }

  .tab-list {
    gap: 17px 15px;
    flex-wrap: wrap;
    @media (max-width: $tablet + px) {
      gap: 10px;
    }
  }

  .tab-item {
    flex: 0 1 calc(50% - 7.5px);
    @media (max-width: $tablet + px) {
      flex: 0 1 calc(50% - 5px);
    }
  }

  .tab-image {
    width: 100%;
    height: 81px;
    margin: 0 auto;
    @media (max-width: $mobileSmall + px) {
      height: 61px;
    }
  }

  .tab-link {
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    opacity: 0.3;
    &.active {
      opacity: 1;
      border-color: #d81921;
    }
  }

  &__small-title {
    @extend .base-text-600;
    color: #000000;
    margin: 0 0 15px 0;
  }
  &__info {
    @extend .base-text;
    font-weight: 400;
    color: #000000;
  }
}
</style>